<template>
  <nuxt-link
    v-if="!crossApp"
    :target="props['target']"
    :to="to"
  >
    <slot />
  </nuxt-link>

  <a
    v-else
    :href="to"
    :target="props['target']"
  >
    <slot />
  </a>
</template>

<script setup>
defineOptions({
  name: 'AtomsCCLink'
})

const props = defineProps({
  to: {
    type: String,
    required: true
  },

  crossApp: {
    type: Boolean,
    default: false
  },

  target: {
    type: String,
    default: '_self'
  }
})
</script>
